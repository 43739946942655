import React, { Fragment, useState, useEffect } from 'react';
import { graphql, useStaticQuery, Link } from 'gatsby';
import _map from 'lodash/map';
import Img from 'gatsby-image';
import { Helmet } from "react-helmet"
import {
  PageWrapper,
  PageInner,
  PageTitle,
  ProjetsWrapper,
  ProjetLink,
  Banner,
  Spacer,
  Text2Col,

} from '../components/Elements';

const priveQuery = graphql`
  {
    page: datoCmsPagePrivee {
      titre
      contenu
      elementsPrives {
          id
          titre
          slug
          imagePrincipale {
            fluid(maxWidth: 800, forceBlurhash: false, imgixParams: { fit: "crop", w: "551", h: "373" , fm: "jpg", auto: "compress" }) {
            ...GatsbyDatoCmsFluid
            }
          }
      }
    }

    banner: file(relativePath: { eq: "bandeau_projets.png" }) {
      childImageSharp {
         fluid( maxWidth:1920) {
            ...GatsbyImageSharpFluid
          }
      }
    }

  }
`;

export default function Ecohabiter2023Page() {
  const data = useStaticQuery(priveQuery);
  const { titre, contenu, elementsPrives } = data.page;
 // const { edges } = data.projets; //tous les projets
console.log(data)
console.log(elementsPrives)
  
 
  return (
    <Fragment>
      <Helmet>
        <title>{titre}</title>
        <meta name="robots" content="noindex,nofollow"></meta>
      </Helmet>
      <PageWrapper>
        <Banner  height="75px">
          <Img fluid={data.banner.childImageSharp.fluid} />
        </Banner>
        
        <PageInner>
          <PageTitle>{titre}</PageTitle>
        
          <Text2Col dangerouslySetInnerHTML={{ __html: contenu }} />
          <Spacer/>
          <ProjetsWrapper>
          {_map(elementsPrives, (elementPrive) => (
            <ProjetLink key={elementPrive.id}>
               <Link to={`/ecohabiter2023/${elementPrive.slug}/`}>
                <Img fluid={elementPrive.imagePrincipale.fluid} alt={elementPrive.titre}/>
                  <h3 className="title">{elementPrive.titre}</h3>
                </Link>
            </ProjetLink>
          ))}
          </ProjetsWrapper>
        </PageInner>
        <Spacer/>
      </PageWrapper>
    </Fragment>
  );
}